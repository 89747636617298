import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const QRCodeList: React.FC = () => {
  const [qrCodes, setQRCodes] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQRCodes = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(API_URL + "/api/listQrCodes", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const formattedQRCodes = response.data.map((qr: any) => ({
          ...qr,
          created_at: new Date(qr.created_at)
            .toLocaleDateString("en-GB")
            .split("/")
            .join("."),
        }));
        setQRCodes(formattedQRCodes);
      } catch (error: any) {
        alert(
          "Failed to fetch QR codes: " +
            (error?.response?.data?.message || error.message)
        );
      }
    };

    fetchQRCodes();
  }, []);

  const handleGoToGenerate = () => {
    navigate("/generateQr");
  };

  const handleGoToDetails = (id: string) => {
    navigate(`/detailsQrCode/${id}`);
  };

  return (
    <div>
      <button onClick={handleGoToGenerate} style={{ marginTop: "10px" }}>
        Generate QR code
      </button>
      <h2>QR Code List</h2>
      <ul>
        {qrCodes.map((qr) => (
          <li key={qr.id} style={{ marginBottom: "20px" }}>
            <div>
              <strong>Name:</strong> {qr.name}
            </div>
            <div>
              <strong>Group:</strong> {qr.group_name}
            </div>
            <div>
              <strong>Created at:</strong> {qr.created_at}
            </div>
            <div>
              <strong>URL:</strong> {qr.url}
            </div>
            <div>
              <strong>Redirect URL:</strong> {qr.redirect_url}
            </div>
            <div>
              <img
                src={qr.qr_code}
                alt={`QR Code for ${qr.name}`}
                style={{ width: "100px" }}
              />
            </div>
            <button
              onClick={() => handleGoToDetails(qr.id)}
              style={{ marginLeft: "10px" }}
            >
              Details
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QRCodeList;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Box, CssBaseline, Drawer, Toolbar } from "@mui/material";
import Signup from "./components/Authentication/Signup";
import Login from "./components/Authentication/Login";
import QRCodeGenerator from "./components/QRCodes/QRCodeGenerator";
import QRCodeList from "./components/QRCodes/QRCodeList";
import QRCodeDetails from "./components/QRCodes/QRCodeDetails";
import { validateToken } from "./services/auth";
import GroupList from "./components/Groups/GroupList";
import GroupDetails from "./components/Groups/GroupDetails";
import Dashboard from "./components/Dashboard/Dashboard";
import Sidebar from "./components/Common/Sidebar";

const drawerWidth = 240;

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const valid = await validateToken();
      setIsLoggedIn(valid);
    };
    checkLoginStatus();
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  if (isLoggedIn === null) {
    return <div>Loading...</div>; // Show loading state while checking token
  }

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* Sidebar */}
        {isLoggedIn && (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <Toolbar />
            <Sidebar />
          </Drawer>
        )}

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 3,
          }}
        >
          <Toolbar />
          <Routes>
            <Route
              path="/signup"
              element={!isLoggedIn ? <Signup /> : <Navigate to="/dashboard" />}
            />
            <Route
              path="/login"
              element={
                !isLoggedIn ? (
                  <Login onLoginSuccess={handleLoginSuccess} />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/dashboard"
              element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/generateQr"
              element={
                isLoggedIn ? <QRCodeGenerator /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/listQr"
              element={isLoggedIn ? <QRCodeList /> : <Navigate to="/login" />}
            />
            <Route
              path="/listGroups"
              element={isLoggedIn ? <GroupList /> : <Navigate to="/login" />}
            />
            <Route
              path="/groupDetails/:groupId"
              element={isLoggedIn ? <GroupDetails /> : <Navigate to="/login" />}
            />
            <Route
              path="/detailsQrCode/:qrCodeId"
              element={
                isLoggedIn ? <QRCodeDetails /> : <Navigate to="/login" />
              }
            />
            <Route
              path="*"
              element={
                isLoggedIn ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;

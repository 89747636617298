import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const GroupList: React.FC = () => {
  const [groups, setGroups] = useState<any[]>([]);
  const [newGroupName, setNewGroupName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(API_URL + "/api/listGroups", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const formattedGroups = response.data.map((group: any) => ({
          ...group,
          created_at: new Date(group.created_at)
            .toLocaleDateString("en-GB")
            .split("/")
            .join("."),
        }));
        setGroups(formattedGroups);
      } catch (error: any) {
        alert(
          "Failed to fetch groups: " +
            (error?.response?.data?.message || error.message)
        );
      }
    };

    fetchGroups();
  }, []);

  const handleAddGroup = async () => {
    if (!newGroupName.trim()) {
      alert("Group name cannot be empty.");
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        API_URL + "/api/createGroup",
        { name: newGroupName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Group added successfully!");
      setGroups((prev) => [
        ...prev,
        {
          ...response.data,
          created_at: new Date()
            .toLocaleDateString("en-GB")
            .split("/")
            .join("."),
        },
      ]);
      setNewGroupName("");
    } catch (error: any) {
      alert(
        "Failed to add group: " +
          (error?.response?.data?.message || error.message)
      );
    }
  };

  const handleDeleteGroup = async (groupId: string) => {
    if (!window.confirm("Are you sure you want to delete this group?")) {
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(API_URL + `/api/deleteGroup/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Group deleted successfully!");
      setGroups((prev) => prev.filter((group) => group.id !== groupId));
    } catch (error: any) {
      alert(
        "Failed to delete group: " +
          (error?.response?.data?.message || error.message)
      );
    }
  };

  const handleViewDetails = (groupId: string) => {
    console.log("handleViewDetails" + groupId);
    navigate(`/groupDetails/${groupId}`);
  };

  const handleGoToGenerate = () => {
    navigate("/generateQr");
  };

  return (
    <div>
      <button onClick={handleGoToGenerate} style={{ marginTop: "10px" }}>
        Generate QR code
      </button>
      <h2>Group List</h2>
      <div>
        <input
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          placeholder="Enter new group name"
          style={{ marginRight: "10px" }}
        />
        <button onClick={handleAddGroup}>Add Group</button>
      </div>
      <ul>
        {groups.map((group) => (
          <li key={group.id} style={{ marginBottom: "20px" }}>
            <div>
              <div>
                <strong>Name:</strong> {group.name}
              </div>
              <div>
                <strong>Created at:</strong> {group.created_at}
              </div>
              <button
                onClick={() => handleViewDetails(group.id)}
                style={{ marginRight: "10px" }}
              >
                Details
              </button>
              {/* <button
                onClick={() => handleDeleteGroup(group.id)}
                style={{ marginLeft: "10px", color: "red" }}
              >
                Delete
              </button> */}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupList;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
} from "recharts";

const API_URL = process.env.REACT_APP_API_URL;

// Define colors for pie chart
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#D0ED57"];

const Dashboard: React.FC = () => {
  const [dashboardStats, setDashboardStats] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDashboardStats = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(`${API_URL}/api/getDashboardStats`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDashboardStats(response.data);
      } catch (err: any) {
        setError(
          "Failed to fetch dashboard stats: " +
            (err?.response?.data?.message || err.message)
        );
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardStats();
  }, []);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        {/* Summary Cards */}
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Scans</Typography>
              <Typography variant="h4">{dashboardStats.totalScans}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Unique Scans</Typography>
              <Typography variant="h4">{dashboardStats.uniqueScans}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Top QR Code</Typography>
              <Typography variant="h5">
                {dashboardStats.topQrCodes[0]?.name || "No Data"}
              </Typography>
              <Typography variant="subtitle1">
                {dashboardStats.topQrCodes[0]?.scans || 0} scans
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Most Scanned Group</Typography>
              <Typography variant="h5">
                {dashboardStats.mostScannedGroup?.name || "No Data"}
              </Typography>
              <Typography variant="subtitle1">
                {dashboardStats.mostScannedGroup?.scans || 0} scans
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Time-Based Scans */}
        <Grid item xs={12} lg={8}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Time-Based Scans</Typography>
              <LineChart
                width={700}
                height={300}
                data={dashboardStats.timeBasedScans}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
              </LineChart>
            </Box>
          </Paper>
        </Grid>

        {/* Top Locations */}
        <Grid item xs={12} lg={4}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Top Locations</Typography>
              <PieChart width={300} height={300}>
                <Pie
                  data={dashboardStats.topLocations}
                  dataKey="scans"
                  nameKey="city"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                >
                  {dashboardStats.topLocations.map((_: any, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <ul>
                {dashboardStats.topLocations.map(
                  (location: any, index: number) => (
                    <li key={index}>
                      {location.city}, {location.country}: {location.scans}{" "}
                      scans
                    </li>
                  )
                )}
              </ul>
            </Box>
          </Paper>
        </Grid>

        {/* Top QR Codes */}
        <Grid item xs={12} lg={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Top QR Codes</Typography>
              <ul>
                {dashboardStats.topQrCodes.map((qrCode: any) => (
                  <li key={qrCode.qr_code_id}>
                    {qrCode.name}: {qrCode.scans} scans
                  </li>
                ))}
              </ul>
            </Box>
          </Paper>
        </Grid>

        {/* Most Scanned Group */}
        <Grid item xs={12} lg={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Most Scanned Group</Typography>
              {dashboardStats.mostScannedGroup ? (
                <Typography>
                  {dashboardStats.mostScannedGroup.name}:{" "}
                  {dashboardStats.mostScannedGroup.scans} scans
                </Typography>
              ) : (
                <Typography>No data available</Typography>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;

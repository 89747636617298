import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const GroupDetails: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();

  const [group, setGroup] = useState<any>(null);
  const [qrCodes, setQRCodes] = useState<any[]>([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGroupDetails = async () => {
      setLoading(true);

      try {
        const token = localStorage.getItem("access_token");
        console.log(groupId);
        const [groupResponse, qrCodesResponse] = await Promise.all([
          axios.get(API_URL + `/api/getGroupDetails/${groupId}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(API_URL + `/api/listQrCodesInGroup/${groupId}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setGroup(groupResponse.data);
        setQRCodes(qrCodesResponse.data);
        setNewGroupName(groupResponse.data.name);
      } catch (err: any) {
        alert("Failed to fetch group details: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupDetails();
  }, [groupId]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleEditGroup = async () => {
    if (!newGroupName.trim()) {
      alert("Group name cannot be empty.");
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        API_URL + "/api/editGroup",
        { id: groupId, name: newGroupName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Group name updated successfully!");
      setGroup((prev: any) => ({ ...prev, name: newGroupName }));
      setEditing(false);
    } catch (err: any) {
      alert("Failed to update group name: " + err.message);
    }
  };

  const handleDeleteGroup = async () => {
    if (!window.confirm("Are you sure you want to delete this group?")) return;

    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(API_URL + `/api/deleteGroup/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Group deleted successfully!");
      navigate(-1);
    } catch (err: any) {
      alert("Failed to delete group: " + err.message);
    }
  };

  const handleRemoveQRCode = async (qrCodeId: string) => {
    if (!window.confirm("Are you sure you want to remove this QR code?"))
      return;

    try {
      const token = localStorage.getItem("access_token");
      await axios.post(
        API_URL + "/api/removeQrCodeFromGroup",
        { qrCodeId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("QR Code removed from group successfully!");
      setQRCodes((prev) => prev.filter((qr) => qr.id !== qrCodeId));
    } catch (err: any) {
      alert("Failed to remove QR Code: " + err.message);
    }
  };

  if (loading) return <div>Loading...</div>;

  if (!group) return <div>No group details available.</div>;

  return (
    <div>
      <button onClick={handleBack} style={{ marginBottom: "20px" }}>
        Back
      </button>
      <h2>Group Details</h2>
      {editing ? (
        <div>
          <input
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            style={{ marginRight: "10px" }}
          />
          <button onClick={handleEditGroup}>Save</button>
          <button
            onClick={() => setEditing(false)}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div>
          <div>
            <strong>Name:</strong> {group.name}
          </div>
          <div>
            <strong>Created At:</strong>{" "}
            {new Date(group.created_at)
              .toLocaleDateString("en-GB")
              .split("/")
              .join(".")}
          </div>
          <button
            onClick={() => setEditing(true)}
            style={{ marginRight: "10px" }}
          >
            Edit
          </button>
          <button
            onClick={handleDeleteGroup}
            style={{ color: "red", marginLeft: "10px" }}
          >
            Delete
          </button>
        </div>
      )}

      <h3>QR Codes in Group</h3>
      {qrCodes.length > 0 ? (
        <ul>
          {qrCodes.map((qr) => (
            <li key={qr.id} style={{ marginBottom: "20px" }}>
              <div>
                <strong>Name:</strong> {qr.name}
              </div>
              <div>
                <strong>URL:</strong> {qr.url}
              </div>
              <button
                onClick={() => handleRemoveQRCode(qr.id)}
                style={{ color: "red", marginTop: "10px" }}
              >
                Remove from Group
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No QR Codes in this group.</p>
      )}
    </div>
  );
};

export default GroupDetails;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const API_URL = process.env.REACT_APP_API_URL;

// Import Leaflet and the necessary images
import L from "leaflet";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const QRCodeDetails: React.FC = () => {
  const { qrCodeId } = useParams<{ qrCodeId: string }>();
  const navigate = useNavigate();

  const [analytics, setAnalytics] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [editQR, setEditQR] = useState<{
    id: string;
    name: string;
    url: string;
    group_id: string | null;
  } | null>(null);
  const [newName, setNewName] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [groups, setGroups] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAnalytics = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          API_URL + `/api/detailsQrCode/${qrCodeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const formattedData = {
          ...response.data,
          created_at: new Date(response.data.created_at)
            .toLocaleDateString("en-GB")
            .split("/")
            .join("."),
        };

        setAnalytics(formattedData);

        setSelectedGroup(response.data.group_id || null);
      } catch (err: any) {
        setError(
          "Failed to fetch QR code details: " +
            (err?.response?.data?.message || err.message)
        );
      } finally {
        setLoading(false);
      }
    };

    const fetchGroups = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(API_URL + "/api/listGroups", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGroups(response.data);
      } catch (err) {
        console.error("Failed to fetch groups", err);
      }
    };

    fetchAnalytics();
    fetchGroups();
  }, [qrCodeId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  const handleBack = () => {
    navigate(-1);
  };

  const handleEditQRCode = (qr: {
    id: string;
    name: string;
    url: string;
    group_id: string | null;
  }) => {
    setEditQR(qr);
    setNewName(qr.name);
    setNewUrl(qr.url);
    setSelectedGroup(qr.group_id);
  };

  const handleSaveQRCode = async () => {
    if (!editQR) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      await axios.put(
        API_URL + "/api/editQrCode",
        {
          id: editQR.id,
          name: newName,
          url: newUrl,
          groupId: selectedGroup,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("QR Code updated successfully!");
      setAnalytics((prev: any) => ({
        ...prev,
        name: newName,
        url: newUrl,
        group_id: selectedGroup,
      }));
      setEditQR(null);
    } catch (error: any) {
      alert(
        "Failed to edit QR code: " +
          (error?.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditQR(null);
    setNewName("");
    setNewUrl("");
    setSelectedGroup(null);
  };

  return (
    <div>
      <button onClick={handleBack} style={{ marginBottom: "20px" }}>
        Back
      </button>
      <button onClick={() => handleEditQRCode(analytics)}>Edit</button>
      {editQR && (
        <div
          style={{
            border: "1px solid black",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <h3>Edit QR Code</h3>
          <div>
            <label>
              <strong>Name:</strong>
            </label>
            <input
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder="Enter new name"
              style={{ display: "block", marginBottom: "10px" }}
            />
          </div>
          <div>
            <label>
              <strong>URL:</strong>
            </label>
            <input
              value={newUrl}
              onChange={(e) => setNewUrl(e.target.value)}
              placeholder="Enter new URL"
              style={{ display: "block", marginBottom: "10px" }}
            />
          </div>
          <div>
            <label>
              <strong>Group:</strong>
            </label>
            <select
              value={selectedGroup || ""}
              onChange={(e) => setSelectedGroup(e.target.value || null)}
              style={{ display: "block", marginBottom: "10px" }}
            >
              <option value="">None</option>
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <button onClick={handleSaveQRCode} disabled={loading}>
              {loading ? "Saving..." : "Save"}
            </button>
            <button onClick={handleCancelEdit} style={{ marginLeft: "10px" }}>
              Cancel
            </button>
          </div>
        </div>
      )}
      <h2>QR Code Details</h2>
      {analytics && (
        <div>
          <div>
            <strong>Name:</strong> {analytics.name}
          </div>
          <div>
            <strong>Created at:</strong> {analytics.created_at}
          </div>
          <div>
            <strong>URL:</strong> {analytics.url}
          </div>
          <div>
            <strong>Group:</strong> {analytics.qr_code_groups?.name || "None"}
          </div>
          <div>
            <strong>Redirect URL:</strong> {analytics.redirectUrl}
          </div>
          <div>
            <strong>QR Code:</strong>
            <br />
            <img
              src={analytics.qrCodeData}
              alt={`QR Code for ${analytics.name}`}
              style={{ width: "150px" }}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <strong>Total Scans:</strong> {analytics.totalScans}
          </div>
          <div>
            <strong>Unique Users:</strong> {analytics.uniqueUsers}
          </div>

          <h3>Time-Based Scans</h3>
          <LineChart width={600} height={300} data={analytics.timeBasedScans}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
          </LineChart>

          <h3>Heatmap Data</h3>
          <MapContainer
            center={[
              analytics.centerPoint.latitude,
              analytics.centerPoint.longitude,
            ]}
            zoom={3}
            style={{ height: "400px", width: "100%" }}
            doubleClickZoom
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {analytics.locationsWithCoordinates.map(
              (loc: any, index: number) => (
                <Marker key={index} position={[loc.latitude, loc.longitude]}>
                  <Popup>
                    {loc.city}, {loc.country} <br /> Scans: {loc.count}
                  </Popup>
                </Marker>
              )
            )}
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default QRCodeDetails;

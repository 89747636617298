import React from "react";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QrCodeIcon from "@mui/icons-material/QrCode";
import GroupIcon from "@mui/icons-material/Group";

const Sidebar = () => {
  return (
    <List>
      {/* Dashboard */}
      <ListItem component={Link} to="/dashboard" disablePadding>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {/* QR Code Generator */}
      <ListItem component={Link} to="/generateQr" disablePadding>
        <ListItemIcon>
          <QrCodeIcon />
        </ListItemIcon>
        <ListItemText primary="Generate QR Code" />
      </ListItem>

      {/* QR Code List */}
      <ListItem component={Link} to="/listQr" disablePadding>
        <ListItemIcon>
          <QrCodeIcon />
        </ListItemIcon>
        <ListItemText primary="QR Code List" />
      </ListItem>

      {/* Groups */}
      <ListItem component={Link} to="/listGroups" disablePadding>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary="Groups" />
      </ListItem>
    </List>
  );
};

export default Sidebar;

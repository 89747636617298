import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL!;

export const validateToken = async (): Promise<boolean> => {
  const token = localStorage.getItem("access_token");
  if (!token) return false;

  try {
    const response = await axios.post(
      API_URL + "/api/validateToken",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.status === 200;
  } catch (error) {
    localStorage.removeItem("access_token");
    return false;
  }
};
